#canvas{

    z-index:-5;
    position: fixed;
    top:0;
    bottom:0;
    height:100%;
    /*width:100%;*/
    animation:1s tester
}
@keyframes tester {
    0%{
        opacity:0;
    }
    100%{
        opacity:1;
    }
}