.kick_section{
    width:100vw;
    padding-bottom: 100px;
    box-sizing: content-box;
}

.kick_container{
    color: white;
    border-radius: 10px;
    background-size: cover;
}


.kick_fin{
    /*width: 100%;*/
    height:100%;
    padding:50px 25px;
    font-weight: 300;
    box-sizing: border-box;
    display: flex;
    gap:50px;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.details{
    transition: 0.6s ease-in-out;
    border-radius: 20px;
    padding:10px 0;
    width:100%;
    max-width:700px;
    display: flex;
    flex-direction: column;
    align-items: start;
}


.details h2{
    font-size:4.5rem;
    text-align: start;
    font-weight:300;
    /*mix-blend-mode: difference;*/
    text-shadow: 0 2px 4px rgba(0, 0, 0, 1);
    font-family: "Lato",sans-serif;
}
.details p{
    font-weight: 300;
    line-height: 36px;
    margin:50px 0;
    font-size:2rem;

    width: 100%;
    max-width: 700px;
    /*mix-blend-mode: difference;*/
    text-shadow: 0 2px 4px rgba(0, 0, 0, 1);
    font-family: "Lato",sans-serif;
    text-align: start;
}

.details h4{
    font-size:2rem;
    text-align: start;
    font-weight:300;
    /*mix-blend-mode: difference;*/
    text-shadow: 0 2px 4px rgba(0, 0, 0, 1);
    font-family: "Lato",sans-serif;
}

.skeg_canvas{
    position: absolute;
    left: 0;
    top:0;
    width: 100%;
    height: 100%;
    z-index:-2
}
.please{
    height:500px;
    width:500px;
}
.nothing{
    height:100%;
    width:100%;
}
.nightskeg{
    display: inline-block;
    margin-top:20px;
    width: 300px;
    height: 300PX;
    order:2;
    transition: 0.6s ease-in-out;
    pointer-events: all;
    opacity:1;

}
a{
    text-decoration: none;
}
.link_kick{
    width: 60%;
    margin: 0 auto;
}
.left-pad{
    margin-top: -45px;
    padding-bottom: 50px;
    text-shadow: 0 0px 0px rgba(0, 0, 0, 0);
}
.empty{
    height:500px;
    width:500px;
    max-width:700px;
    border: 1px solid white;
}