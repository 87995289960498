
/*Vector that is the stroke for the a */
#red-vector{
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    animation: dash 0.6s linear forwards;
    animation-delay: 2s;

}
#lazer-a{
    transform: scale(1.03);
    animation: scale1 1s linear forwards 3s,pulseRed2 16s infinite 3s;

    transform-origin: top;
}
/*Container for red part of laser that is a triangle*/
#second{
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    animation: dash linear forwards;
    animation-duration: 1s;
    animation-delay: 2s;

}
/*Lasers that goes through letters*/
#lazer-er,#lazer-r,#lazer-st {
    stroke-dasharray: 100;
    stroke-dashoffset: -100;
    animation: laserStrike3 20s infinite;


}
#lazer-er{
    animation-delay: 5s;
}
#lazer-r{
    animation-delay: 5.25s;

}
#lazer-st{

    animation-delay: 5.5s;
}


/*Red part of T*/
#test{
    stroke-dasharray: 500;
    stroke-dashoffset: 500;
    animation: change 20s infinite;
    animation-delay: 5.7s;
}

#grad-t{
    /*fill: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);*/
    /*fill: url(#MyGradient);*/
    animation: finalShot 20s linear infinite 5.7s ;
    opacity:0;
}
#beacon{
    animation: pulseRed 16s infinite 3s ;


}

#lazer-text{
    animation: addBlueGlow 6s infinite 3s;
}
/*Simple animation for a laser stroke*/
@keyframes dash {
    0% {
        fill: none;
    }

    100%{
        stroke-dashoffset: 0;

    }

}
@keyframes finalShot {

    0% {
        opacity:0;
    }
    1%{
        opacity:1;
    }
    10%{
        opacity:0;
    }
    100%{
        opacity:0;
    }
}



@keyframes pulseRed {
    0%{
        filter: drop-shadow(0px 0px 0px #FF0000)
    }
    25%{
        filter: drop-shadow(0px 0px 5px #e73737) drop-shadow(0px 0px 5px #e73737) drop-shadow(0px 0px 5px #e73737) drop-shadow(0px 0px 5px #e73737);
    }
    50%{
        filter: drop-shadow(0px 0px 0px #FF0000)
    }
    75%{
        filter: drop-shadow(0px 0px 5px #e73737) drop-shadow(0px 0px 5px #e73737) drop-shadow(0px 0px 5px #e73737) drop-shadow(0px 0px 5px #e73737);
    }
    100%{
        filter: drop-shadow(0px 0px 0px #FF0000)
    }
}
@keyframes pulseRed2 {
    0%{
        filter: drop-shadow(0px 0px 0px #FF0000)
    }
    25%{
        filter: drop-shadow(0px 0px 5px #e73737)  drop-shadow(0px 0px 5px #e73737);
    }
    50%{
        filter: drop-shadow(0px 0px 0px #FF0000)
    }
    75%{
        filter: drop-shadow(0px 0px 5px #e73737)  drop-shadow(0px 0px 5px #e73737);
    }
    100%{
        filter: drop-shadow(0px 0px 0px #FF0000)
    }
}


/*Animation for letters*/
@keyframes dash2 {
    0%{
        stroke-dashoffset: 500;
        stroke-dasharray: 500;
    }
    50%{
        stroke-dashoffset: 0;
    }
    100% {
        stroke-dashoffset: 0;

    }
}

@keyframes sparkStrike3 {
    0%{
        stroke-dashoffset: 1000;
    }
    10%{
        stroke-dashoffset: 0;
    }
    20%{
        stroke-dashoffset: -1000;
    }
    100%{
        stroke-dashoffset: -1000;
    }
}


@keyframes laserStrike3 {
    0%{
        stroke-dashoffset: 100;
    }
    1%{
        stroke-dashoffset: 0;
    }
    2%{
        stroke-dashoffset: -100;
    }
    100%{
        stroke-dashoffset: -100;
    }
}
@keyframes fadeIn {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

@keyframes fadeInTitle {
    0%{
        opacity: 0;
    }
    75%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
/*Pulsing animation for red part of T*/
@keyframes change {

    0%{
        stroke-dashoffset: 500;
    }

    5%{
        stroke-dashoffset: 0;
    }

    95%{
        stroke-dashoffset: 0;
    }
    100%{
        stroke-dashoffset: -500;
    }
}

@keyframes scale1 {
    0%{
        transform: scale(1.03);
    }
    80%{
        transform: scale(1);
        /*transform: rotate(300deg);*/
    }
    100%{
        transform: scale(1);
    }
}

