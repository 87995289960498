@import url(https://fonts.googleapis.com/css?family=Lato:100,200,300,400);

.expertise_section{
    margin-top:10px;
    min-height:50vh;
    width:100vw;
    max-width: 100vw;
    overflow: auto;

}

.expertise_intro{
    width: 100%;
    max-width: 700px;
    padding:30px;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 1);
    font-family: "Lato",sans-serif;
    font-weight:300;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}
.reveal{
    text-shadow: 0 2px 4px rgba(0, 0, 0, 1);
}

.expertise_intro p{
    font-weight: 300;
    line-height: 36px;
    margin: 50px 0;
    font-size: 2rem;
    width: 100%;
    max-width: 700px;
    /* mix-blend-mode: difference; */
    text-shadow: 0 2px 4px rgba(0, 0, 0, 1);
    font-family: "Lato",sans-serif;
    text-align: start;
    hyphens: auto;
}
.expertise_intro h2{
    font-size: 5rem;
    /*text-align: center;*/
    font-weight: 300;
    text-align: start;
    /* mix-blend-mode: difference; */
    text-shadow: 0 2px 4px rgba(0, 0, 0, 1);
    font-family: "Lato",sans-serif;
}


.expertise_container{
    color: white;
    width:100%;
}



.expertise_info{

    width: 100%;
    height: 100%;
    padding: 150px 0;
    gap:50px;
    display: flex;
    flex-wrap: wrap-reverse;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}


.points{
    width: 100%;
    max-width:750px;
    padding:20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    text-align: left;
    justify-content: center;
    gap:10px;

}

.points ul{
    border-radius: 0;
    width:100%;
    max-width: 320px;
    border: 1px solid rgba(255, 255, 255, 0.22);
    padding: 10px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height:220px;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 1);
    font-family: "Lato",sans-serif;
    font-weight:300;
}
.points ul:hover{
    background-color: rgba(255, 255, 255, 0.2);
}

.icon{
    font-size: 4.5rem;
}
.list h3{
    text-align: left;
    margin-left:10px;
    padding:10px;
    font-size:1.8rem;
    /*mix-blend-mode: difference;*/
    text-shadow: 0 2px 4px rgba(0, 0, 0, 1);
    font-family: "Lato",sans-serif;
    font-weight:300;
}
.list li{
    color: white;
    margin-left:10px;
    padding:10px;
    list-style: none;
    font-size:1.5rem;
    /*mix-blend-mode: difference;*/
    text-shadow: 0 2px 4px rgba(0, 0, 0, 1) !important;
    font-family: "Lato",sans-serif;
    font-weight:300;


}

@media (max-width: 700px) {
    .expertise_intro h2{
        /*font-size: 40px;*/
        text-align: center;
    }
    .expertise_intro p{
        hyphens:auto;
        /*font-size:14px;*/
        text-align: center;
    }
}