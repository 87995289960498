@import url(https://fonts.googleapis.com/css?family=Lato:100,200,300,400);

.brand_container{
    width: 100vw;
    /*margin-top:-25vh;*/

    display: flex;
    /*justify-content: center;*/
    flex-direction: column;
    align-items: center;
    /*background: linear-gradient(180deg,#284b63,#87ceeb);*/

}

.revealUp2{
    transition: 0.6s ease-in-out;
}



.brand_container h2{
    padding:50px;
    font-size: 5rem;
    color: white;
    /*mix-blend-mode: difference;*/
    text-shadow: 0 2px 4px rgba(0, 0, 0, 1);
    font-family: "Lato",sans-serif;
    font-weight:300;
}
.logos{
    display: grid;
    grid-template-rows: repeat(2,1fr);
    grid-template-columns: repeat(4,1fr);
    gap:0px 8vw;
    justify-content: center;
    align-items: center;
}
.logos a img{
    max-width:140px;
    width:15vw;
    height:  auto;
    transition: 0.3s ease-in-out;
}
.logos a img:hover{
    transform:scale(1.1);
}

.brand_container p{
    padding:0 50px 50px 50px;
    font-size: 2rem;
    line-height: 30px;
    max-width: 800px;
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-weight: lighter;
}


@media only screen and (max-width: 600px){
}