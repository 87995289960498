@import url(https://fonts.googleapis.com/css?family=Lato:100,200,300,400);

.register{
    z-index:100;
    cursor: pointer;
    font-size:20px;
    position: absolute;
    bottom: 60px;
    padding: 15px;
    width:90%;
    transition: 0.4s ease-in-out;
    background-color: rgba(255, 255, 255, 0.19);
    border:1px solid white;
    color: rgb(255, 255, 255);
    text-shadow: 0 2px 4px rgba(0, 0, 0, 1);
    text-decoration: none;
    pointer-events: all;
    font-family:'Lato', sans-serif;

}
.register:hover{
    background-color: rgba(255, 255, 255, 0.01);
    color: white;
}


.funky{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width:100vw;
    gap:30px;
    padding:50px 0;

}

.interest_title{
    text-shadow: none;
    position: absolute;
    top:30px;
    font-size: 30px;
}
.tha_claw{
    position: absolute;
    left: 0;
    top:0;
    width: 100%;
    height: 100%;
    z-index:-2
}
.list-item {
    display: inline-block;
    margin-top:20px;
    width: 300px;
    height: 300PX;
    order:2;
    transition: 0.6s ease-in-out;
    pointer-events: all;
    opacity:1;
}

.card {
    /*border-top: 2px solid white;*/
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 0;
    font-family: 'Lato', sans-serif;
    /*overflow: hidden;*/
    text-decoration: none;

    width:90vw;
    max-width:350px;

}
.product_title{
    order:1;
}
.product_title h2{
    color: white;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 1);
    font-size:4rem;
    margin-top:50px;
    font-weight:300;

}

.product_title h3{
    color: white;
    font-size:2.5rem;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 1);
    margin-top:15px;
    font-weight:300;
}

.usp{
    order:3;
    display:flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.usp p{
    padding-top:25px;
    height:300px;
    font-family: 'Lato', sans-serif;
    /*border-top:2px solid white;*/
    /*border-bottom:2px solid white;*/
    font-size:1.9rem;
    line-height: 35px;
    color: white;

    text-shadow: 0 2px 4px rgba(0, 0, 0, 1);
    /*overflow-wrap: break-word;*/
    /*hyphens: auto;*/
    font-weight:300
}

.list-item > div:nth-child(1) {
    width: 100%;
    height: 100%;
    z-index:10;

}


@media (max-width: 700px){
    /*.product_title h2{*/
    /*    !*display: none;*!*/
    /*    font-size:40px;*/
    /*}*/
    /*.product_title h3{*/
    /*    font-size:25px;*/
    /*}*/
    /*.usp p{*/
    /*    !*display: none;*!*/
    /*    font-size:20px;*/
    /*}*/
    /*.list-item{*/
    /*    width:250px;*/
    /*    height:250px;*/
    /*}*/
    /*.tree_container{*/
    /*    !*display: none;*!*/
    /*}*/

}




/*Add this again when needed*/


@keyframes pulseRed3 {
    0%{
        filter: drop-shadow(0px 0px 3px #FF0000) drop-shadow(0px 0px 5px #e73737)
    }
    50%{
        filter: drop-shadow(0px 0px 5px #e73737) drop-shadow(0px 0px 5px #e73737) drop-shadow(0px 0px 5px #e73737) drop-shadow(0px 0px 5px #e73737);
    }
    100%{
        filter: drop-shadow(0px 0px 3px #FF0000) drop-shadow(0px 0px 5px #e73737)
    }
}


@keyframes laserStrike4 {
    0%{
        stroke-dashoffset: -1000;
    }

    50%{
        stroke-dashoffset: 0;
    }
    100%{
        stroke-dashoffset: 1000;
    }
}