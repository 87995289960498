@import url(https://fonts.googleapis.com/css?family=Lato:100,200,300,400);

.mobile_canvas{
    position: absolute;
    left: 0;
    top:0;
    width: 100vw;
    height: 100%;
    z-index:-2
}
.interest{
    z-index:100000;
    cursor: pointer;
    position: absolute;
    bottom: 20px;
    height:20px;
    right:10px;
    padding: 15px;
    width:45vw;
    border-radius: 10px;
    font-size:15px;
    transition: 0.4s ease-in-out;
    background-color: rgba(255, 255, 255, 0.5);
    border:1px solid white;
    color: black;
    text-decoration: none;
}
.interest:hover{
    background-color: rgba(255, 255, 255, 0.01);
    color: white;
}

.list-item-mobile {
    display: inline-block;
    transition: 0.6s ease-in-out;
    /*margin-top:20px;*/
    width: 200px;
    height: 200px;
    margin:10px auto;

}

.list-item-mobile > div:nth-child(1) {
    width: 100%;
    height: 100%;
    z-index:10
}

.card-mobile {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 300px;
    width:100vw;
    padding:50px 25px;
    box-sizing: border-box;
    text-decoration: none;
    color: white !important;

}
.card_mobile_info{
    display: flex;
    flex-direction: column;
    align-items: center;
    width:100%;
}


.card_mobile_info h2{
    color: white;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 1);
    font-size:35px;
    margin-top:50px;
    font-weight:300;

}

.card_mobile_info h3{
    color: white;
    font-size:25px;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 1);
    margin-top:15px;
    font-weight:300;
}


.card_mobile_info p{
    padding-top:25px;
    height:300px;
    border-top:2px solid white;
    border-bottom:2px solid white;
    font-size:15px;
    line-height: 35px;
    color: white;
    padding-bottom: 25px;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 1);
    font-weight:300
}