.size_award{
    width:50vw;
    max-width: 300px;
    position: relative;
    margin: 300px auto 50px auto;
    animation: 3s zoomss ease-in-out;

}
.size_award img{
    width:50vw;
    max-width: 300px;
    height:auto;
    animation: 3s zoomss ease-in-out;
}
.size_award h2{
    position: absolute;
    top:20%;
    text-align: center;
    width: 100%;
    color: #e6ac10;
    font-size:60px;
    animation: 3s zoomss ease-in-out;
}
.award_container{
    color: white;
    font-size: 30px;
}
@keyframes zoomss {
    0%{
        scale:0.9;
    }
    82%{
        transform: rotate(0deg);
    }
    86%{
        transform: rotate(2deg);
    }
    90%{
        transform: rotate(-2deg);
    }
    94%{
        transform: rotate(0deg);
    }
    100%{
        scale:1;
    }
}

@media (max-width: 500px) {
    .size_award h2{
        font-size: 30px;
    }
}