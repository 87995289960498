@import url(https://fonts.googleapis.com/css?family=Lato:100,200,300,400);

#marine_section,#energy_section{

    margin-top: -90vh;
    width:100vw;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
    padding:100px 25px;
}

.info_container h2,.info_container p,.info_container h3,.info_container button,.features h3, .specs h3,.features ul li, .specs, ul li{
    text-shadow: none !important;

    color: black;
}
.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.ReactModal__Overlay--after-open{
    opacity: 1;
}

.ReactModal__Overlay--before-close{
    opacity: 0;
}
.ReactModal__Overlay--after-close{
    opacity: 0;
}

.marine_container,.energy_container{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

}
.marine_container{
    flex-wrap: wrap;

}
.energy_container{
    flex-wrap: wrap-reverse;
}
.marine_info,.energy_info{
    margin: 50px auto;
    color: white;
    width: 100%;
    /*padding:10px;*/
    max-width: 1200px;
    margin-bottom:-50px;

}


.energy_info h2,.marine_info h2{
    font-size:5rem;
    font-weight:300;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 1);
}
.energy_info p,.marine_info p {
    margin: 50px auto;
    padding:10px;
    font-weight:300;
    line-height: 36px;
    max-width:800px;

    /*mix-blend-mode: difference;*/
    text-shadow: 0 2px 4px rgba(0, 0, 0, 1);
    font-size:2rem;
}


.reveal{
    transition: 0.6s ease-in-out;
}

.info_container{

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    border-bottom: 1px solid #1c1c1c;
    min-height:80vh;
}

.carousel .slide{
    background: none;
}

.shop-social{
    padding-top:25px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:15px;
    text-shadow: 0 0px 0px rgba(0, 0, 0, 0);

}
.shop-social a{
    margin-top: -15px;
    color: #232222;
    font-size:2rem;
    transition:0.3s ease-in-out;
    cursor: pointer;
    width:32px;
    height:32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(194, 194, 194, 0.28);
    /*padding:3px 6px;*/
    border-radius: 200px;
    text-shadow: 0 0px 0px rgba(0, 0, 0, 0);
}

.imageContainer{

    width:90vw;
    max-width:600px;
    height:100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.carouselImg{
    width:90vw;
    max-width:600px;
    background-color: rgba(51, 153, 255, 0);
}
.closeModal{
    z-index: 1000000;
    position: fixed;
    top:10px;
    right:10px;
    border: none;
    border-radius: 200px;
    width:40px;
    height:40px;
    background: rgba(178, 178, 178, 0.3);
    font-size:2.5rem;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}
.closeModal:hover{
    transform:scale(1.1);
}

.detailContainer{

    width:80vw;
    max-width:600px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap:20px;
}
.detailContainer h2{
    font-family: 'Lato', sans-serif;
    font-size: 6rem;
    font-weight: 300;
    color: black;

}
.detailContainer h3{
    font-family: 'Lato', sans-serif;
    font-size: 2rem;
    font-weight: 300;
    color: black;
}
.detailContainer h4{
    font-family: 'Lato', sans-serif;
    font-size: 2rem;
    font-weight: 300;
    color: black;
    text-shadow: none;
}
.react-tabs{
    min-height:600px;
    padding-left: 30px;
}

.react-tabs__tab-list{
    margin-top:50px;
    font-size:2rem;
    padding:10px;
}
.react-tabs__tab{

}
.detailContainer h5{
    font-size: 1.2rem;
    font-weight: 300;
    margin-top:-15px;
    color: black;
    text-shadow: none;
    margin-bottom: 30px;

}

.detailContainer p {
    font-weight: 300;
    font-size: 1.5rem;
    font-family: 'Lato', sans-serif;

}

.preorderButton{
    background: #323232;
    width:80%;
    padding: 8px 10px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    color: white !important;
    border:2px solid white;
    transition: 0.1s ease-in-out;
    font-family: 'Lato', sans-serif;
    font-size: 2rem;
    font-weight: 300;
    margin: 50px auto 20px;

}
.kickButton{
    background: white;
    border:2px solid  #05ce78;
    width:100%;
    margin: 0 auto;
    padding: 5px 10px;
    font-size:2rem;
    font-weight: lighter;
    color: white;
    cursor: pointer;
    transition: 0.5s ease-in-out;

}
.kickButton:hover{
    background: #323232;
    border: 2px solid #05ce78;
}
.preorderButton:hover{
    background: white;
    border: 2px solid #323232;
    color: #323232 !important;
}

.kickstarter{
    font-family: "FrankfurterPlain",sans-serif;
    text-shadow: 0 0px 0px rgba(0, 0, 0, 1);
    color: #05ce78;
}
.features,.specs {

    margin: 25px 0 0;
    list-style-type: none;
    font-size: 1.5rem;

}

.features > li, .specs > li {
    text-indent: -5px;
}
.features> li:before,.specs >li:before {
    content: "-  ";
    text-indent: 5px;
}

.contactForm h2{
    width: 100%;
    text-align: center;
    margin:30px 0;
    font-family: 'Lato', sans-serif;
    font-size: 4rem;
    font-weight: 300;
    color: #3b3939;
}
input::placeholder, textarea::placeholder {
    color: #3b3939;
    font-size: 0.875em;
}

input:hover::placeholder, textarea:hover::placeholder {
    color: #e2dedb;
    font-size: 0.875em;
}

input {
    font-size: 0.875em;
    width:100%;
    height: 50px;
    padding: 0 15px 0 15px;
    box-sizing: border-box;
    background: transparent;
    outline: none;
    color: #3b3939;
    border: solid 1px #b3aca7;
    border-bottom: none;
    transition: all 0.2s ease-in-out;
}

input:hover {
    background: #3b3939;
    color: #e2dedb;
}

form {
    position: relative;
    max-width: 500px;
    width:90vw;
    margin: 50px auto 100px auto;
}
textarea {
    width:100%;
    height: 110px;
    max-height: 110px;
    box-sizing: border-box;
    padding: 15px;
    background: transparent;
    outline: none;
    font-family: 'Lato', sans-serif;
    font-size: 0.875em;
    border: solid 1px #b3aca7;
    transition: all 0.2s ease-in-out;
}

textarea:hover {
    background: #3b3939;
    color: #e2dedb;
}

#submit {
    width: 100%;
    box-sizing: border-box;
    padding: 0;
    margin: -5px 0 0 0;
    font-family: 'Lato', sans-serif;
    font-size: 0.875em;
    color: #b3aca7;
    outline:none;
    cursor: pointer;
    border: solid 1px #b3aca7;
    border-top: none;
}

#submit:hover {
    color: #e2dedb;
}


/*@media only screen and (max-width: 600px){*/
/*    .energy_info h2,.marine_info h2{*/

/*        font-size: 30px;*/
/*    }*/
/*    .energy_info p,.marine_info p {*/
/*        font-size:13px;*/
/*        line-height: 18px;*/
/*    }*/
/*}*/