/*@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400&display=swap');*/

*
{
    margin: 0;
    padding: 0;
    /*text-shadow: 1px 1px black;*/
    /*font-family: 'Roboto', sans-serif !important;*/
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    z-index:-1;
}


body{
    height:100%;
    background: #1d2024;
    overflow-x: hidden !important;
    scroll-behavior: smooth;
}

.App {
    text-align: center;


    max-width: 100vw;
    max-height: 100vw;
}

section:nth-child(odd)
{
    justify-content: flex-end;
}

