.left,.right,.up{
    position: fixed;
    z-index: 10000;
    display: flex;
    flex-direction: row;
    align-items: center;
    animation: fadeInTitle 5s;

}
.right{
    flex-direction: row-reverse;
}

.right button,.left button,.up button{

    padding:25px;
    font-size:20px;
    cursor: pointer;



}




/* CSS */
.right button,.left button,.up button {
    --c: #fff;
    /* text color */
    background: #63687a;
    /* background color */
    color: #0000;
    border: none;
    transform: perspective(500px) rotateY(calc(20deg*var(--_i,-1)));
    text-shadow: calc(var(--_i,-1)* 0.08em) -.01em 0   var(--c),
    calc(var(--_i,-1)*-0.08em)  .01em 2px #0004;
    outline-offset: .1em;
    transition: 0.3s;}
.left button{
    transform: perspective(500px) rotateY(calc(20deg*var(--_i,1)));
    text-shadow: calc(var(--_i,-1)* 0.08em) -.01em 0   var(--c),calc(var(--_i,-1)*-0.08em)  .01em 2px #0004;
}
.right button:hover,.left button:hover,.up button:hover,
.right button:focus-visible,.left button:focus-visible,.up button:focus-visible {
    --_p: 0%;
    --_i: 1;
}
.left button:hover,.left button:focus-visible{
    --_i: -1;
}
.right button:active,.left button:active,.up button:active {
    text-shadow: none;
    color: var(--c);
    box-shadow: inset 0 0 9e9q #0005;
    transition: 0s;
}

.right button,.left button,.up button {
    border-radius:10px;
    font-weight: bold;
    font-size: 2rem;
    margin: 0;
    cursor: pointer;
    padding: .1em .3em;
}


.right{
    right:10px;
    top:60%;
}
.up{
    right:50%;
    top:10px;
    opacity: 0;
}
.left{
    left:10px;
    top:60%;
}
.right p, .left p,.up p {
    color: white;
    font-size: 15px;
    padding: 10px;
}

@media (max-width: 700px) {
    .right button,.left button,.up button{
        font-size: 12px;
        padding:5px;
    }
    .right p, .left p,.up p{
        font-size: 10px;
    }
}
@media (hover: hover) {
    .left button:hover,.right button:hover,.up button:hover{
        background: rgba(255, 255, 255, 1);
        color: black;
        opacity:1;
    }
}