
footer{
    margin-top:20vh;
    width:100vw;
    max-width:100vw;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    color: white;
    box-sizing: border-box;

}
.footer_container{
    width:80%;
    padding:50px;
    border-top: 1.5px solid white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /*mix-blend-mode: difference;*/
    text-shadow: 0 2px 4px rgba(0, 0, 0, 1);
    font-family: "Lato",sans-serif;
    font-weight:300;
}

.info{
    width:100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.location{
    width: 33%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: start;

}
.number{
    width: 33%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.email{
    width: 33%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.uil-map-marker,.uil-phone,.uil-envelope,.uil-at{
    font-size:2rem;
    margin-bottom:-10px;
}
.email a,.number a,.location a{
    font-size:2rem;
    margin-left:10px;
    color: white;
    text-decoration: none;
}
.email a:hover,.number a:hover, .location a:hover{
    transform: scale(1);
    color:deepskyblue;

}

.social_media{
    padding-top:25px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left:-20px;
    gap:25px;
}

.social_media a{
    color: rgba(255, 255, 255, 0.7);
    font-size:3rem;
    transition:0.3s ease-in-out;
    cursor: pointer;
}


.social_media a:hover{
    transform: scale(1.5);
    color: white;

}

.follow_text{
    position: absolute;
    bottom:0;
    left:50%;
}

@media (max-width: 1400px) {
    .info{
        gap:20px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: left;
        margin-left:0;
        width:100%;
    }

    /*.uil-envelope{*/
    /*    margin-left:50px;*/
    /*}*/

    .email a,.number a,.location a{
        font-size:1.3rem;

    }
    .email,.number,.location{
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: left;

    }
}