@import url(https://fonts.googleapis.com/css?family=Lato:100,200,300,400);

.mission_section{
    width:100vw;

    box-sizing: content-box;
}

.mission_info{

    width: 100%;
    height:100%;
    padding:50px 25px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    box-sizing: border-box;
    display: flex;
    gap:50px;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}



.mission_container{
    /*margin: 0 auto;*/
    color: white;
    border-radius: 10px;
    background-size: cover;
}


.imager{
    width: 100%;
    /*height:300px;*/
    max-width:750px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    /*margin-bottom: 100px;*/

}
.imager img{
    border-radius: 5px;
    max-width:700px;
    height:auto;
    opacity: 0.8;
    border: 1.5px solid white;
}
.goDown{
    margin-top: -50px;
    /*position: absolute;*/
    /*margin: 0 auto;*/
    animation: goDownNow 1.5s ease-in-out infinite;

}
.goDown .uil{
    font-size: 4rem;

}

.headerGoDown{
    position: absolute;
    top:90vh;
    left:45vw;
    right:45vw;
    animation: goDownNow 1.5s ease-in-out infinite;
    color: white;
    font-size: 5rem;
    transition: 0.5s ease-in-out;
}
.headerGoDown .uil{
    font-weight: 100;
}
.reveal{
    /*opacity: 0;*/
    transition: 0.5s ease-in-out;
}
.something{
    transition: 0.6s ease-in-out;
    border-radius: 20px;
    padding:10px 0;
    width:100%;
    max-width:800px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.something h2{
    font-size:5rem;
    text-align: center;
    font-weight:300;
    /*mix-blend-mode: difference;*/
    text-shadow: 0 2px 4px rgba(0, 0, 0, 1);
    font-family: "Lato",sans-serif;

}

.something p{
    font-weight: 300;
    line-height: 36px;
    margin:50px 0;
    font-size:2rem;

    width: 100%;
    max-width: 800px;
    /*mix-blend-mode: difference;*/
    text-shadow: 0 2px 4px rgba(0, 0, 0, 1);
    font-family: "Lato",sans-serif;
    text-align: center;
}
@media only screen and (max-width: 800px){
    .something h2{
        text-align: center;
    }
    .something p{
        hyphens:auto;
        text-align: center;

    }
}



@keyframes goDownNow{
    0%{
        transform:translateY(10px);
    }
    50%{
        transform:translateY(0);
    }
    100%{
        transform:translateY(10px);
    }
}
