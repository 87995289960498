@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300&display=swap');

#opening{
    width:100vw;
    height:100vh;
    box-sizing: content-box;
    overflow: hidden;

}

.pain{
    object-fit: cover;
    background-attachment: fixed;
    opacity: 0.5;
    z-index: -1;
}



.navbar{
    width:100vw;
    height:20px;
    position: fixed;
    top: 10vh;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    z-index:101;
    animation: woah 10s ease-in-out;
}

nav svg{
    transform: scale(1);
    max-width:40px;

    transition: 1s ease-in-out;
    cursor: pointer;
}
#beacon2,#beacon1{
    transition: 0.3s ease-in-out;
}
nav svg:hover #beacon2{
    filter:   drop-shadow(0px 0px 2px #e73737) drop-shadow(0px 0px 2px #e73737) drop-shadow(0px 0px 2px #e73737);
}

nav svg:hover #beacon1{
    filter:   drop-shadow(0px 0px 2px #e73737) drop-shadow(0px 0px 2px #e73737);
}



.navbar ul{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap:2vw;
    list-style: none;
    transition: 1s ease-in-out;

}
nav a{
    text-decoration: none;
    font-size: 1.6rem;
    color: white;
    text-transform: uppercase;
    border-bottom: 1px solid rgba(0,0,0,0);

}
.navbar ul li p{
    padding:10px;
    text-decoration: none;
    font-size: 1.6rem;
    color: white;
    text-transform: uppercase;
    border-bottom: 1px solid rgba(0,0,0,0);
    cursor: pointer;
    mix-blend-mode: difference;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 1);
    font-family: "Lato",sans-serif;
    font-weight:400;

}


.navbar ul li{
    transition:0.3s ease;

}

.navbar ul li:nth-child(1){

    position: relative;
    padding-right:40px ;
    margin-right:-20px;
}

.navbar ul li:nth-child(1):after {
    content: '';
    height: 200%;
    width: 2px;
    position: absolute;
    right: 0;
    top: -20px;
    background-color: #ffffff;
}

.navbar ul li:nth-child(2){

    position: relative;
    padding-left:40px ;
    margin-left:-20px;
}

.navbar ul li:nth-child(2):before {
    content: '';
    height: 200%;
    width: 2px;
    position: absolute;
    left: 0;
    top: -20px;
    background-color: #ffffff;
}

.navbar ul li p:hover{
    /*border-bottom: 1px solid #3399ff;*/
    color: #3399ff;

}

.svg_container{
    width:90%;
    margin: 0 auto;
    height:35vh;
    transform:scale(0.8);
    animation: fadeIn 5s, showOff 6s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
    margin-top:10vh;
    z-index:100000;
    transition: 0.6s ease-in-out;
    pointer-events: All;

}

.svg_container svg{
    max-width:1200px;

    stroke-dashoffset: 500;
    stroke-dasharray: 500;
    animation: dash2 3s forwards 2s;

}

.svg_container h1{
    max-width: 800px;
    width:70%;
    margin-top:-80px;
    align-items: end;
    text-align: end;
    animation: fadeInTitle 6s;
    color:white;
    font-family: 'Lato', sans-serif;
    font-weight: 300;
    font-size:2.2rem;
    transition: 0.5s ease-in-out;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 1);

}


@keyframes woah{
    0%{
        transform: translateY(-20vh);
    }
    100%{
        transform: translateY(0vh);
    }
}


@keyframes showOff{
    0%{
        transform: scale(1.0);
        /*transform: translateY(100%);*/
    }
    70%{
        transform: scale(1.0);
        /*transform: translateY(100%);*/
    }
    100%{
        transform: scale(0.8);
        /*transform: translateX(0);*/
    }
}

@media (max-width: 700px){
    .navbar ul li p{
        font-size:1.3rem;
    }

    .navbar ul li p{
        font-size:1.3rem;
    }


    .navbar ul li:nth-child(1){

        position: relative;
        padding-right:25px ;
        margin-right:-18px;
    }

    .navbar ul li:nth-child(2){

        position: relative;
        padding-left:25px ;
        margin-left:-18px;
    }


}

/*}*/
@media (max-width: 900px){

    .svg_container h1{
        font-size:12px;
        width:80%;
        margin-top:-30px;
    }
}